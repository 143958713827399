import { useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';

import { type Statement } from '@amalia/core/types';
import { assert } from '@amalia/ext/typescript';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { StatementsApiClient } from '../api-client/statements.api-client';

export const statementsQueryKeys = {
  all: () => ['statements'] as const,
  detailsByPlanPeriodUser: (planId: Plan['id'], userId: UserContract['id'], periodId: Period['id']) =>
    [...statementsQueryKeys.all(), 'by-plan-period-user', planId, userId, periodId] as const,
  list: (statementIds: Statement['id'][]) => [...statementsQueryKeys.all(), 'by-ids', statementIds] as const,
  forecasted: (statementId?: Statement['id']) => [...statementsQueryKeys.all(), 'forecasted', statementId] as const,
} as const;

export const useStatementByPlanPeriodUser = (
  planId?: Plan['id'],
  userId?: UserContract['id'],
  periodId?: Period['id'],
) =>
  useQuery({
    enabled: !!planId && !!userId && !!periodId,
    queryKey: statementsQueryKeys.detailsByPlanPeriodUser(planId!, userId!, periodId!),
    queryFn: async () => {
      assert(planId, 'Plan ID must be defined');
      assert(userId, 'User ID must be defined');
      assert(periodId, 'Period ID must be defined');
      return StatementsApiClient.findStatementByCriteria(periodId, planId, userId);
    },
  });

export const useStatementsByIds = (statementIds: Statement['id'][]) =>
  useQuery({
    enabled: !!statementIds.length,
    queryKey: statementsQueryKeys.list(statementIds),
    queryFn: async () => StatementsApiClient.getStatements(statementIds),
    select: (statements) => keyBy(statements, 'id'),
    placeholderData: [],
  });

export const useStatementForecast = (statement?: Statement) =>
  useQuery({
    enabled: !!statement?.forecastId,
    queryKey: statementsQueryKeys.forecasted(statement?.id),
    queryFn: async () => {
      assert(statement, 'Statement not found');
      return StatementsApiClient.getForecastedStatement(statement.forecastId!, statement.id, true);
    },
  });

import { useIsFetching, useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { type Plan, type Rule } from '@amalia/payout-definition/plans/types';

import { plansForecastQueryKeys } from '../plan-forecast/plan-forecast.keys';
import { rulesV2MutationKeys, rulesV2QueryKeys } from '../queries.keys';

import { RulePlanAssignment } from './rule-plan-assignment.api-client';

export const useRulePlanConfigurations = (planId?: Plan['id']) =>
  useQuery({
    queryKey: rulesV2QueryKeys.configurations.ofPlan.all(planId!),
    queryFn: () => RulePlanAssignment.getRuleConfigurations(planId!),
    enabled: !!planId,
  });

export const useRulePlanConfiguration = (planId: Plan['id'], ruleId: Rule['id']) =>
  useQuery({
    queryKey: rulesV2QueryKeys.configurations.ofPlan.details(planId, ruleId),
    queryFn: () => RulePlanAssignment.getRuleConfiguration(planId, ruleId),
  });

export const useUpdateRuleAssignment = ({ ignoreSnack }: { ignoreSnack: boolean } = { ignoreSnack: false }) => {
  const queryClient = useQueryClient();
  const { snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: rulesV2MutationKeys.configurations.updateRuleAssignment(),
    mutationFn: RulePlanAssignment.updateRuleAssignment,
    onSuccess: async (ruleAssignmentUpdated) => {
      if (!ignoreSnack) {
        snackSuccess(formatMessage({ defaultMessage: 'Rule updated successfully.' }));
      }
      await queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.all() });
      await queryClient.invalidateQueries({
        queryKey: plansForecastQueryKeys.details(ruleAssignmentUpdated.ruleAssignment.planId),
      });
    },
  });
};

export const useIsRuleAssignmentLoading = (planId: string): boolean => {
  const isFetching = useIsFetching({
    queryKey: rulesV2QueryKeys.configurations.ofPlan.all(planId),
  });

  const isMutating = useIsMutating({
    mutationKey: rulesV2MutationKeys.configurations.updateRuleAssignment(),
  });

  return !!isFetching || !!isMutating;
};

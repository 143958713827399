import { omit } from 'lodash';
import { type SetRequired } from 'type-fest';

import { http } from '@amalia/core/http/client';
import { type EngineError } from '@amalia/core/types';
import { type PlanTemplateDehydrated } from '@amalia/payout-calculation/types';
import {
  type PatchPlanForecastingConfigurationRequest,
  type CreateHighlightedKpiRequest,
  type CreatePlanRequest,
  type DuplicatePlanRequest,
  type HighlightedKpi,
  type HighlightedKpiIdentifier,
  type ImportTokenFromOtherPlanRequest,
  type PatchPlanRequest,
  type PatchPlanVisibility,
  type PatchPlanWorkflowRequest,
  type Plan,
  type PutPlanHighlightedKpisRequest,
} from '@amalia/payout-definition/plans/types';

export class PlansApiClient {
  public static async list(filters?: {
    userIds?: string[];
    planIds?: string[];
    periodId?: string;
  }): Promise<SetRequired<Plan, 'planAssignements'>[]> {
    let qs = '';
    if (filters) {
      const { userIds, planIds, periodId } = filters;

      qs = [
        ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
        ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
        ...(periodId ? [`periodId=${periodId}`] : [null]),
      ]
        .filter(Boolean)
        .join('&');
    }
    const { data } = await http.get<SetRequired<Plan, 'planAssignements'>[]>(`/plans?${qs}`);
    return data;
  }

  public static async get(planId: string): Promise<Plan> {
    const { data } = await http.get<Plan>(`/plans/${encodeURIComponent(planId)}`);
    return data;
  }

  public static async getPlanTemplate(
    planId: string,
  ): Promise<{ template?: PlanTemplateDehydrated; error?: EngineError }> {
    const { data } = await http.get<{ template?: PlanTemplateDehydrated; error?: EngineError }>(
      `/plans/${encodeURIComponent(planId)}/template`,
    );

    return data;
  }

  public static async create(plan: CreatePlanRequest): Promise<Plan> {
    const { data } = await http.post<Plan>('/plans/', plan);
    return data;
  }

  public static async update(plan: Plan): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/${plan.id}`, plan);
    return data;
  }

  public static async patchPlanVisibility(planId: string, patch: PatchPlanVisibility): Promise<Plan> {
    const { data } = await http.patch<Plan>(`/plans/${planId}/visibility`, patch);
    return data;
  }

  public static async patchPlanWorkflow({
    planId,
    ...patchPlanWorkflowRequest
  }: PatchPlanWorkflowRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (await http.patch<Plan>(`/plans/${encodeURIComponent(planId)}/workflow`, patchPlanWorkflowRequest)).data;
  }

  public static async patchPlan({
    planId,
    ...patchPlanRequest
  }: PatchPlanRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (await http.patch<Plan>(`/plans/${encodeURIComponent(planId)}`, patchPlanRequest)).data;
  }

  public static async patchPlanForecastingConfiguration({
    planId,
    ...patchPlanForecastingConfigurationRequest
  }: PatchPlanForecastingConfigurationRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (
      await http.patch<Plan>(
        `/plans/${encodeURIComponent(planId)}/forecasting-configuration`,
        patchPlanForecastingConfigurationRequest,
      )
    ).data;
  }

  public static async putPlanHighlightedKpis({
    planId,
    ...request
  }: PutPlanHighlightedKpisRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (await http.put<Plan>(`/plans/${encodeURIComponent(planId)}/highlighted-kpis`, request)).data;
  }

  public static async archive(planId: Plan['id']): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/archive/${encodeURIComponent(planId)}`, { archived: true });
    return data;
  }

  public static async unarchive(planId: Plan['id']): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/archive/${encodeURIComponent(planId)}`, { archived: false });
    return data;
  }

  public static async delete(planId: Plan['id']): Promise<void> {
    await http.delete(`/plans/${encodeURIComponent(planId)}`);
  }

  public static async upsertHighlightedKpi(
    planId: string,
    highlightedKpi: CreateHighlightedKpiRequest & { identifier: HighlightedKpiIdentifier },
  ): Promise<HighlightedKpi> {
    const { data } = await http.put<HighlightedKpi>(
      `/plans/${planId}/highlighted-kpis/${highlightedKpi.identifier}`,
      omit(highlightedKpi, 'identifier'),
    );

    return data;
  }

  public static async deleteHighlightedKpi(planId: string, identifier: HighlightedKpiIdentifier) {
    await http.delete(`/plans/${planId}/highlighted-kpis/${identifier}`);
  }

  public static async duplicatePlan({
    planId,
    ...duplicatePlanRequest
  }: DuplicatePlanRequest & { planId: Plan['id'] }): Promise<Plan> {
    const { data: newPlan } = await http.post<Plan>(
      `/plans/${encodeURIComponent(planId)}/duplicate`,
      duplicatePlanRequest,
    );
    return newPlan;
  }

  public static async importTokenFromOtherPlan({
    planId,
    ...importTokenFromOtherPlanRequest
  }: ImportTokenFromOtherPlanRequest & {
    planId: Plan['id'];
  }): Promise<void> {
    await http.post(`/plans/${encodeURIComponent(planId)}/import-token-from-other-plan`, {
      ...importTokenFromOtherPlanRequest,
    });
  }
}

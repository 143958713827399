import { isEmpty } from 'lodash';

import { http } from '@amalia/core/http/client';
import {
  type CreateStatementOverwriteRequest,
  type Overwrite,
  type Statement,
  type StatementForecast,
} from '@amalia/core/types';
import {
  type BulkReviewRequest,
  type WorkflowStatementStateAction,
} from '@amalia/payout-collaboration/workflows/types';
import { type UserContract } from '@amalia/tenants/users/types';

const apiEndpoint = '/statements';

export class StatementsApiClient {
  public static async getStatements(ids: string[]): Promise<Statement[]> {
    const { data } = await http.post<Statement[]>(`${apiEndpoint}/getByIds`, {
      ids,
    });
    return data;
  }

  public static async findStatements(
    periodId?: string,
    planId?: string,
    teamId?: string,
    userIds?: string[],
    withKpiResults?: boolean,
  ): Promise<Statement[]> {
    const { data } = await http.get<Statement[]>(apiEndpoint, {
      params: {
        periodId,
        planId,
        teamId,
        userIds: !isEmpty(userIds) ? userIds : undefined,
        relations: 'user,plan,period,adjustments',
        withKpiResults: withKpiResults ?? false,
      },
    });

    return data;
  }

  public static async getStatement(statementId: string): Promise<Statement> {
    const { data } = await http.get<Statement>(`${apiEndpoint}/${statementId}`);
    return data;
  }

  public static async getForecastedStatement(
    statementForecastedId: string,
    statementId: string,
    withObjectsToDisplay: boolean,
  ): Promise<StatementForecast> {
    const { data } = await http.get<StatementForecast>(
      `${apiEndpoint}/${statementId}/forecasts/${statementForecastedId}`,
      {
        params: { withObjectsToDisplay },
      },
    );
    return data;
  }

  public static async findStatementByCriteria(periodId: string, planId: string, userId: string): Promise<Statement> {
    const { data } = await http.get<Statement>(`${apiEndpoint}/findOneByCriteria`, {
      params: {
        periodId,
        planId,
        userId,
      },
    });
    return data;
  }

  // REVIEW
  public static async processWorkflowStep(
    statementId: string,
    workflowAction: WorkflowStatementStateAction,
    isNotify: boolean,
  ): Promise<Statement> {
    const { data } = await http.patch<Statement>(`${apiEndpoint}/${statementId}?isNotify=${isNotify.toString()}`, {
      workflowAction,
    });
    return data;
  }

  public static async reviewStatements(calculationRequest: BulkReviewRequest, isNotify: boolean): Promise<void> {
    await http.post(`${apiEndpoint}/reviews?isNotify=${isNotify.toString()}`, calculationRequest);
  }

  // RIGHTS
  public static async listUsersThatCurrentUserCanSee(planIds?: string[], teamIds?: string[]): Promise<UserContract[]> {
    const url = `${apiEndpoint}/listUsers`;
    const params: string[] = [];
    Object.values(planIds ?? []).forEach((planId: string) => {
      params.push(`planIds=${planId}`);
    });
    Object.values(teamIds ?? []).forEach((teamId: string) => {
      params.push(`teamIds=${teamId}`);
    });

    const paramsString = params.length ? `?${params.join('&')}` : '';
    const { data } = await http.get<UserContract[]>(`${url}${paramsString}`);
    return data;
  }

  public static async createOverwrite(
    statementId: string,
    createStatementOverwriteRequest: CreateStatementOverwriteRequest,
  ): Promise<Overwrite> {
    const { data } = await http.post<Overwrite>(
      `${apiEndpoint}/${statementId}/overwrites`,
      createStatementOverwriteRequest,
    );
    return data;
  }

  public static async clearStatementOverwrite(statementId: string, overwriteId: string) {
    const { data } = await http.delete(`${apiEndpoint}/${statementId}/overwrites/${overwriteId}`);
    return data;
  }
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { type Plan, type Rule } from '@amalia/payout-definition/plans/types';

import { plansForecastQueryKeys } from '../plan-forecast/plan-forecast.keys';
import { plansQueryKeys, rulesV2QueryKeys } from '../queries.keys';

import { FiltersApiClient } from './filters.api-client';
import { filtersMutationKeys, filtersQueryKeys } from './filters.keys';

export const useFilters = (planId?: string) =>
  useQuery({
    queryKey: planId ? filtersQueryKeys.ofPlan(planId) : filtersQueryKeys.list(),
    queryFn: () => FiltersApiClient.list(planId),
  });

export const useFilter = (filterId: string) =>
  useQuery({
    queryKey: filtersQueryKeys.details(filterId),
    queryFn: () => FiltersApiClient.get(filterId),
  });

export const useCreateFilter = ({ planId, ruleId }: { planId: string; ruleId: string }) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: filtersMutationKeys.create(),
    mutationFn: FiltersApiClient.create,
    onSuccess: async (filterCreated) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: filtersQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.details(planId, ruleId) }),
        queryClient.invalidateQueries({
          queryKey: filterCreated.planId
            ? plansQueryKeys.template.ofPlan(filterCreated.planId)
            : plansQueryKeys.template.all(),
        }),
        queryClient.invalidateQueries({ queryKey: plansForecastQueryKeys.details(planId) }),
      ]);
      snackSuccess(formatMessage({ defaultMessage: 'Table created' }));
    },
    onError: (error) => {
      snackError(
        formatMessage({ defaultMessage: `Error while creating filter: {message}` }, { message: error.message }),
      );
    },
  });
};

export const usePatchFilter = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: filtersMutationKeys.patch(),
    mutationFn: FiltersApiClient.patch,
    onSuccess: async (filterUpdated) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: filtersQueryKeys.all() }),
        queryClient.invalidateQueries({
          queryKey: filterUpdated.planId
            ? plansQueryKeys.template.ofPlan(filterUpdated.planId)
            : plansQueryKeys.template.all(),
        }),
      ]);
      snackSuccess(formatMessage({ defaultMessage: 'Table updated' }));
    },
    onError: (error) => {
      snackError(
        formatMessage({ defaultMessage: `Error while updating table: {message}` }, { message: error.message }),
      );
    },
  });
};

export const useDeleteFilter = ({
  planId,
  ruleId,
  onErrorOverride,
}: {
  planId: Plan['id'];
  ruleId: Rule['id'];
  onErrorOverride?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: filtersMutationKeys.delete(),
    mutationFn: FiltersApiClient.delete,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Table deleted successfully.' }));

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.details(planId, ruleId) }),
        queryClient.invalidateQueries({ queryKey: filtersQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.all() }),
        queryClient.invalidateQueries({ queryKey: plansForecastQueryKeys.details(planId) }),
      ]);
    },
    onError: (err) => {
      if (onErrorOverride) {
        onErrorOverride(err);
      } else {
        snackError(
          formatMessage(
            { defaultMessage: 'An error occurred while deleting the table: {errorMessage}.' },
            { errorMessage: err.message },
          ),
        );
      }
    },
  });
};

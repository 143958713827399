import { type Merge } from 'type-fest';

import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

import { TokenType } from './meta';

export enum VariableType {
  statement = 'statement',
  object = 'object',

  user = 'user',
  team = 'team',
  plan = 'plan',
}

export const variableTypeToTokenType: Record<VariableType, TokenType> = {
  [VariableType.statement]: TokenType.VARIABLE,
  [VariableType.object]: TokenType.FIELD,
  [VariableType.user]: TokenType.QUOTA,
  [VariableType.plan]: TokenType.QUOTA,
  [VariableType.team]: TokenType.QUOTA,
} as const;

export interface VariableFormatOptionsColumn {
  machineName: string;
  name: string;
  format: FormatsEnum;
}

export enum TableVariableFormat {
  BUILDER = 'BUILDER',
  FORMULA = 'FORMULA',
}

export interface VariableFormatOptionsTable extends VariableFormatOptions {
  format: TableVariableFormat;
  columns: VariableFormatOptionsColumn[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface -- Could be retyped later.
export interface VariableFormatOptions {}

export interface VariableFormulaOptions {
  convertCurrency?: {
    from: string;
    to: string;
  };
  round?: {
    precision: number;
  };
  default?: {
    value: number | string;
  };
}

export interface Variable {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  description: string | null;
  formula: string | null;
  formatOptions: VariableFormatOptions | null;
  frequency: PeriodFrequencyEnum;
  type: VariableType;
  planId: string | null;
  ruleId: string | null;

  // If variable is type object, we have the object definition.
  object?: CustomObjectDefinition | null;
  objectId: string | null;

  isQuotaRequired: boolean | null;

  name: string;
  machineName: string;
  format: FormatsEnum;
  formulaOptions: VariableFormulaOptions | null;
}

// TODO - circular dependency with Plan
export interface VariableWithPlan extends Variable {
  plan: {
    id: string;
    name: string;
    machineName: string;
  } | null;
}

export type VariableDefinition = Pick<
  Variable,
  'description' | 'format' | 'formatOptions' | 'formula' | 'formulaOptions' | 'id' | 'machineName' | 'name' | 'type'
>;
export type VariablesMap = Record<string, Variable>;

// All the editable fields, but in Partial since it's a patch.
export type PatchVariableRequest = Partial<
  Pick<
    Variable,
    | 'description'
    | 'format'
    | 'formatOptions'
    | 'formula'
    | 'formulaOptions'
    | 'frequency'
    | 'isQuotaRequired'
    | 'machineName'
    | 'name'
    | 'objectId'
    | 'planId'
    | 'ruleId'
    | 'type'
  >
>;

// Only a few fields are required at creation, other are the same than the patch.
export type CreateVariableRequest = PatchVariableRequest & Pick<Variable, 'format' | 'name' | 'type'>;

export type StatementVariable = Merge<
  Variable,
  {
    formula: string;
    frequency: PeriodFrequencyEnum.null;
    type: VariableType.statement;

    object?: null;
    objectId: null;
    isQuotaRequired: null;
  }
>;

export type FieldVariable = Merge<
  Variable,
  {
    frequency: PeriodFrequencyEnum.null;
    type: VariableType.object;
    formula: string;

    // If variable is type object, we have the object definition.
    object?: CustomObjectDefinition;
    objectId: string;

    isQuotaRequired: null;
  }
>;

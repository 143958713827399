import { isEmpty } from 'lodash';

import { http } from '@amalia/core/http/client';
import { type StatusStatementType, type UserStatementsFacets, type UserStatementsPage } from '@amalia/core/types';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type PlanIsHiddenQueryChoices } from '@amalia/payout-definition/plans/types';

const apiEndpoint = '/userStatements';

export interface FindUserStatementsParameters {
  isForecast?: boolean;
  nbStatementsPerPage?: number;
  period?: Period;
  planId?: string;
  planHiddenStatus?: PlanIsHiddenQueryChoices;
  searchQuery?: string;
  sortColumn?: string;
  sortOrder?: 'asc' | 'desc';
  statementStatus?: StatusStatementType;
  teamId?: string;
  userIds?: string[];
}

export type GetUserStatementsFacetsParameters = {
  isForecast?: boolean;
  periodId?: string;
  planHiddenStatus?: PlanIsHiddenQueryChoices;
  planIds?: string[];
  statementStatus?: StatusStatementType;
  teamId?: string;
};

export class UserStatementsApiClient {
  public static async findUserStatements(
    searchOptions: FindUserStatementsParameters,
    page: number,
  ): Promise<UserStatementsPage> {
    const {
      userIds,
      nbStatementsPerPage,
      teamId,
      planId,
      period,
      planHiddenStatus,
      statementStatus,
      isForecast,
      searchQuery,
      sortColumn,
      sortOrder,
    } = searchOptions;

    const { data } = await http.get<UserStatementsPage>(apiEndpoint, {
      params: {
        periodId: period?.id,
        userIds,
        planId,
        teamId,
        isForecast,
        searchQuery,
        planHiddenStatus,
        statementStatus: !isEmpty(statementStatus) ? statementStatus : undefined,
        page,
        limit: nbStatementsPerPage || 18,
        sortColumn,
        sortOrder,
      },
    });

    return data;
  }

  public static async getUserStatementsFacets({
    periodId,
    planIds,
    teamId,
    statementStatus,
    isForecast,
    planHiddenStatus,
  }: GetUserStatementsFacetsParameters): Promise<UserStatementsFacets> {
    const { data } = await http.get<UserStatementsFacets>(`${apiEndpoint}/facets`, {
      params: {
        isForecast: isForecast || undefined,
        periodId: periodId || undefined,
        planHiddenStatus: planHiddenStatus || undefined,
        planIds: planIds || undefined,
        statementStatus: statementStatus || undefined,
        teamId: teamId || undefined,
      },
    });

    return data;
  }
}

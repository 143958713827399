import { isNil } from 'lodash';

import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';
import { type VariableDefinition, VariableType } from '@amalia/amalia-lang/tokens/types';
import {
  type ComputedStatement,
  type ComputedStatementSummary,
  type DatasetRow,
  deleteAt,
  formatUserFullName,
  getFieldAsOption,
  type Overwrite,
  OverwriteTypesEnum,
  type PaymentAmountsByCategory,
  PaymentCategory,
  replaceAt,
  type Statement,
} from '@amalia/core/types';
import { type RecordContent, type RecordContentPropertyType } from '@amalia/data-capture/connectors/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type BaseCustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import {
  ComputedItemTypes,
  type ComputedOverwrite,
  type ComputedVariable,
  type ComputeEngineResult,
  type Dataset,
  DatasetType,
  type FilterDataset,
  type MetricsDataset,
  type QuotaDataset,
} from '@amalia/payout-calculation/types';
import {
  type ComputedPlanRuleFieldsToDisplay,
  HidableElementVisibility,
  RuleType,
} from '@amalia/payout-definition/plans/types';

import { FormulaService } from '../formula/formula.service';

export const getRuleDefinitionFromIdInStatement = (computedStatement: ComputedStatement, ruleId: string) =>
  computedStatement.definitions.plan.rules.find((r) => r.id === ruleId);

export const getVariableDefinitionFromMachineNameInStatement = (
  computedStatement: ComputedStatement,
  variableMachineName: string,
): VariableDefinition | undefined => computedStatement.definitions.variables[variableMachineName];

export const getVariableDefinitionFromIdInStatement = (
  computedStatement: ComputedStatement,
  variableId: string,
): VariableDefinition | undefined =>
  Object.values(computedStatement.definitions.variables).find((v) => v.id === variableId);

export const getComputedVariableInStatement = <TValue extends ComputeEngineResult = ComputeEngineResult>(
  computedStatement: ComputedStatement | ComputedStatementSummary,
  variableMachineName: string,
): ComputedVariable<TValue> | undefined =>
  computedStatement.computedObjects.find(
    (co): co is ComputedVariable<TValue> =>
      co.type === ComputedItemTypes.VARIABLE && co.variableMachineName === variableMachineName,
  );

/**
 * Get all fields that could be displayed in the current context.
 *
 * @param computedStatement
 * @param dataset
 */
export const getAllFieldsInContext = (computedStatement: ComputedStatement, dataset: Dataset) => {
  // Get the definition related to the selected dataset.
  const definition = computedStatement.definitions.customObjects[dataset.customObjectDefinition.machineName] as
    | BaseCustomObjectDefinition
    | undefined;

  // Find labels and name for this definition.
  const properties = definition?.properties
    ? Object.values(definition.properties)
        .filter(Boolean)
        .map((p) => ({ name: p.machineName, label: p.name }))
    : [];

  const computationItems = (dataset.computedItems ?? [])
    // Find all computed object variables.
    .filter((co) => co.variableType === VariableType.object)
    .map((co) => ({
      label: computedStatement.definitions.variables[co.variableMachineName].name,
      name: co.variableMachineName,
    }));

  // Merge both.
  return [...properties, ...computationItems];
};

export const getFieldsToDisplay = (
  computedStatement: ComputedStatement,
  filterDataset: FilterDataset | MetricsDataset | QuotaDataset,
  formula?: AmaliaFormula,
): ComputedPlanRuleFieldsToDisplay[] => {
  // Get the definition of the filter.
  const filterDefinition = computedStatement.definitions.filters[filterDataset.filterMachineName];
  // Get the definition related to the selected filter.
  const definition = computedStatement.definitions.customObjects[filterDefinition.customObjectDefinitionMachineName] as
    | BaseCustomObjectDefinition
    | undefined;

  let fields: { label: string; name: string }[] = [];

  if (formula && definition) {
    // If it's a regular dataset.
    if (filterDataset.type === DatasetType.filter) {
      // Add external ids fields by default.
      definition.externalIds.forEach((externalId) => {
        const field = getFieldAsOption(definition, externalId);
        if (field) fields.push(field);
      });

      // Add name field by default
      const field = getFieldAsOption(definition, 'name');
      if (field) {
        fields.push(field);
      }
    } else if (filterDataset.type === DatasetType.metrics) {
      // Add userId field.
      const fieldUserId = getFieldAsOption(definition, 'userId');
      if (fieldUserId) {
        fields.push(fieldUserId);
      }

      // Add statementId field.
      const fieldStatementId = getFieldAsOption(definition, 'statementId');
      if (fieldStatementId) {
        fields.push(fieldStatementId);
      }

      // Add periodId field.
      const fieldPeriodId = getFieldAsOption(definition, 'periodId');
      if (fieldPeriodId) {
        fields.push(fieldPeriodId);
      }
    } else {
      // Add userId field.
      const fieldUserId = getFieldAsOption(definition, 'userId');
      if (fieldUserId) {
        fields.push(fieldUserId);
      }

      // Add quota name field.
      const fieldLabel = getFieldAsOption(definition, 'name');
      if (fieldLabel) {
        fields.push(fieldLabel);
      }

      // Add startDate field.
      const fieldStartDate = getFieldAsOption(definition, 'startDate');
      if (fieldStartDate) {
        fields.push(fieldStartDate);
      }

      // Add endDate field.
      const fieldEndDate = getFieldAsOption(definition, 'endDate');
      if (fieldEndDate) {
        fields.push(fieldEndDate);
      }

      // Add value field.
      const fieldValue = getFieldAsOption(definition, 'value');
      if (fieldValue) {
        fields.push(fieldValue);
      }
    }

    if (filterDataset.customObjectDefinition.machineName === 'objectMetric') {
      // Add externalId field.
      const fieldExternalId = getFieldAsOption(definition, 'rowExternalId');
      if (fieldExternalId) {
        fields.push(fieldExternalId);
      }

      // Add custom object definition field.
      const fieldCustomObjectDefinitionMachineName = getFieldAsOption(definition, 'customObjectDefinitionMachineName');
      if (fieldCustomObjectDefinitionMachineName) {
        fields.push(fieldCustomObjectDefinitionMachineName);
      }
    }
    const filterFormula = filterDefinition.condition;
    // Add fields retrieved from filter formula.
    if (filterFormula) {
      // getFormulaObjectsFields is a recursive method that returns
      // a fields array composed from existing fields with fields retrieved from formula.
      fields = FormulaService.getFormulaObjectsFields(filterFormula, definition, computedStatement, fields);
    }

    // Add fields from rule formula.
    fields = FormulaService.getFormulaObjectsFields(formula, definition, computedStatement, fields);
  }

  return fields.map((field) => ({
    ...field,
    displayStatus: HidableElementVisibility.ON_DISPLAY,
  }));
};

export const applyOverwriteToStatementDataset = (rows: DatasetRow[], overwrite: Overwrite): DatasetRow[] => {
  const index = rows.findIndex((row) => row.externalId === overwrite.appliesToExternalId);
  // If we didn't find this row, return the untouched dataset.
  if (index === -1) {
    return rows;
  }

  if (overwrite.overwriteType === OverwriteTypesEnum.FILTER_ROW_REMOVE) {
    return rows.filter((row) => row.externalId !== overwrite.appliesToExternalId);
  }
  const row = rows[index];

  const newRow: DatasetRow = {
    ...row,
    content: {
      ...row.content,
      [overwrite.field]: (overwrite.overwriteValue as RecordContent)[overwrite.field],
    },
    overwrites: (row.overwrites ?? [])
      // Remove existing overwrites on same field and same line
      .filter((ov) => ov.field !== overwrite.field)
      // And add the new one.
      .concat(overwrite),
  };
  return replaceAt(rows, index, newRow);
};

export const clearOverwriteInStatementDataset = (rows: DatasetRow[], overwrite: Overwrite): DatasetRow[] => {
  // If we clear a row added overwrite, we just remove the row.
  if (overwrite.overwriteType === OverwriteTypesEnum.FILTER_ROW_ADD) {
    return rows.filter((row) => row.externalId !== overwrite.appliesToExternalId);
  }

  const index = rows.findIndex((row) => row.externalId === overwrite.appliesToExternalId);
  // If we didn't find this row, return the untouched dataset.
  if (index === -1) {
    return rows;
  }

  // Finding the overwrite to delete.
  const row = rows[index];
  const overwritesOnRow = row.overwrites;
  const overwriteToRemoveIndex = overwritesOnRow?.findIndex((o) => o.id === overwrite.id) ?? -1;

  if (overwriteToRemoveIndex === -1 || !overwritesOnRow) {
    return rows;
  }

  const overwriteToRemove = overwritesOnRow[overwriteToRemoveIndex];

  // Building the new row.
  const newRow = {
    ...row,
    // Put back the source value in the row.
    content: {
      ...row.content,
      [overwriteToRemove.field]: (overwriteToRemove.sourceValue as RecordContent | undefined)?.[
        overwriteToRemove.field
      ],
    },
    // And delete the overwrite.
    overwrites: deleteAt(overwritesOnRow, overwriteToRemoveIndex),
  };

  return replaceAt(rows, index, newRow);
};

export const applyOverwriteToComputedStatement = (
  computedStatement: ComputedStatement,
  overwrite: Overwrite,
): ComputedStatement => {
  const newStatement = { ...computedStatement };
  // Replacing kpi.
  // Build the computed overwrite from the overwrite.
  const computedOverwrite: ComputedOverwrite = {
    id: overwrite.id,
    creator: formatUserFullName(overwrite.creator),
    createdAt: overwrite.createdAt!, // createdAt should not be optional.
    sourceValue: overwrite.sourceValue as RecordContentPropertyType,
    overwriteValue: overwrite.overwriteValue as RecordContentPropertyType,
    scope: overwrite.scope,
  };

  // Find the computed variable in the computed objects.
  const index = computedStatement.computedObjects.findIndex(
    (co) => co.type === ComputedItemTypes.VARIABLE && co.variableMachineName === overwrite.field,
  );

  // Immutable replace of the computedVariable in the statement.
  newStatement.computedObjects = replaceAt(computedStatement.computedObjects, index, {
    ...computedStatement.computedObjects[index],
    overwrite: computedOverwrite,
  });

  return newStatement;
};

export const removeOverwriteFromComputedStatement = (
  computedStatement: ComputedStatement,
  overwrite: ComputedOverwrite | Overwrite,
): ComputedStatement => ({
  ...computedStatement,
  computedObjects: computedStatement.computedObjects.map((co) =>
    co.overwrite?.id === overwrite.id
      ? {
          ...co,
          currency: isCurrencyValue(overwrite.sourceValue) ? overwrite.sourceValue.symbol : co.currency,
          // Put back the source value.
          value: isNil(overwrite.sourceValue)
            ? undefined
            : isCurrencyValue(overwrite.sourceValue)
              ? (overwrite.sourceValue.value ?? undefined)
              : (overwrite.sourceValue as number),
          // Delete the overwrite.
          overwrite: undefined,
        }
      : co,
  ),
});

/**
 * Check if statement contains a hold and release or split rule.
 * @param statement
 * @param paymentTotalByType
 */
export const showMultiPayouts = (
  statement: Statement | null,
  paymentTotalByType: PaymentAmountsByCategory,
): boolean => {
  const containsHoldRules = !!statement?.results.definitions.plan.rules.some((r) =>
    [RuleType.HOLD_AND_RELEASE, RuleType.SPLIT].includes(r.type),
  );

  return (
    (paymentTotalByType[PaymentCategory.hold] ?? 0) >= 0.5 ||
    paymentTotalByType[PaymentCategory.achievement] !== paymentTotalByType[PaymentCategory.paid] ||
    containsHoldRules
  );
};

/**
 * Get total result from a statement plan variable.
 * @param statement
 */
export const getTotalFromStatementPlanVariable = (
  statement: Statement,
):
  | {
      label: string;
      value: number | null | undefined;
      format: FormatsEnum;
      currency: CurrencySymbolsEnum | undefined;
    }
  | undefined => {
  const resultToTake = statement.resultSummary || statement.results;

  const totalVariableId = resultToTake.definitions.plan?.totalVariableId;

  if (!totalVariableId) {
    return undefined;
  }

  if (!resultToTake.definitions.variables) return undefined;

  const variables = resultToTake.definitions.variables;
  const totalVariable = Object.values(variables).find((v) => v.id === totalVariableId);
  if (!totalVariable) {
    return undefined;
  }

  const totalComputedObject = resultToTake.computedObjects.find(
    (co) => (co as ComputedVariable).variableMachineName === totalVariable.machineName,
  );

  return {
    label: totalVariable.name,
    value: totalComputedObject?.value as number,
    format: totalVariable.format,
    currency: totalComputedObject?.currency,
  };
};

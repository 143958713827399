import { mapValues } from 'lodash';

import { http, qsStringify } from '@amalia/core/http/client';
import {
  type CustomObject,
  type CustomObjectListDetails,
  type FiltersType,
  type Overwrite,
  type PaginatedQuery,
  type PatchCustomObjectRequest,
  type PatchCustomObjectResponse,
} from '@amalia/core/types';
import { type RecordContent } from '@amalia/data-capture/connectors/types';
import { OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';

const apiEndpoint = '/objects';
const e = encodeURIComponent;

export class CustomObjectsApiClient {
  public static async quickSearch(definition: string, searchText: string): Promise<CustomObject[]> {
    return (
      await http.get<CustomObject[]>(`${apiEndpoint}/${e(definition)}/quick-search`, {
        params: {
          q: searchText,
        },
      })
    ).data;
  }

  /**
   * List all objects values matching a definition.
   * @param definition
   * @param pagination
   * @param filters
   * @param overwrite
   * @param externalIds
   */
  public static async getObjects(
    definition: string,
    pagination: PaginatedQuery,
    filters?: FiltersType[] | null,
    overwrite = false,
    externalIds?: string,
  ): Promise<CustomObjectListDetails> {
    const params = {
      overwrite,
      ...(externalIds && { externalIds }),
      ...(filters?.length && { filter: filters.map((filter) => JSON.stringify(filter)) }),
    };

    const url = `${apiEndpoint}/${e(definition)}?${qsStringify(params)}`;

    const { items: responseItems, totalItems } = (
      await http.get<{
        items: CustomObject[];
        totalItems: number;
      }>(url, {
        params: {
          // Not sure we can use nullish coalescing here
          limit: pagination.limit || 10,
          page: pagination.page ?? 0,
          q: pagination.search?.trim(),
          sort: pagination.sort,
          desc: pagination.desc,
        },
      })
    ).data;

    return {
      totalItems,

      // All items
      items: responseItems.map((item, index) => ({
        index,
        internalId: item.id,
        externalId: item.externalId,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        content: item.content,
        overwrites: item.overwrites,
        // Add all properties of items
        ...mapValues(item.content, (value) => (isCurrencyValue(value) ? value.value : value)),
      })),
    };
  }

  public static async purgeObjectsByDefinition(definitionId: string) {
    return http.delete(`${apiEndpoint}/${e(definitionId)}`);
  }

  public static async patchCustomObject({
    definitionMachineName,
    objectExternalId,
    patch,
  }: {
    definitionMachineName: string;
    objectExternalId: string;
    patch: PatchCustomObjectRequest;
  }): Promise<PatchCustomObjectResponse> {
    const { data } = await http.patch<PatchCustomObjectResponse>(
      `${apiEndpoint}/${e(definitionMachineName)}/records/${e(objectExternalId)}`,
      patch,
    );
    return data;
  }

  public static async clearCustomObject({
    definitionMachineName,
    objectExternalId,
    overwriteId,
  }: {
    definitionMachineName: string;
    objectExternalId: string;
    overwriteId: string;
  }) {
    const { data } = await http.post<CustomObject>(
      `${apiEndpoint}/${e(definitionMachineName)}/records/${e(objectExternalId)}/clear`,
      { overwriteId },
    );
    return data;
  }

  public static async bulkOverwrite(
    customObjectDefinitionMachineName: string,
    overwriteObject: {
      overwrites: {
        appliesToExternalId: string;
        field: string;
        overwriteValue: RecordContent;
      }[];
    },
  ): Promise<{ status: number }> {
    const batchSize = 10;
    const nbBatches = Math.ceil(overwriteObject.overwrites.length / batchSize);

    let nbErrors = 0;
    let lastError = '';

    for (let i = 0; i < nbBatches; i++) {
      try {
        await http.post<Overwrite>(`${apiEndpoint}/${e(customObjectDefinitionMachineName)}/records/bulk`, {
          patches: overwriteObject.overwrites
            .slice(i * batchSize, i * batchSize + batchSize)
            .map((overwrite): PatchCustomObjectRequest & { objectExternalId: string } => ({
              objectExternalId: overwrite.appliesToExternalId,
              field: overwrite.field,
              overwriteValue: overwrite.overwriteValue,
              scope: OverwriteScopeEnum.GLOBAL,
            })),
        });
      } catch (e) {
        nbErrors++;
        lastError = (e as Error).message;
      }
    }

    if (nbErrors > 0) {
      throw new Error(`${nbErrors} batches over ${nbBatches} were in error. Last error: ${lastError}`);
    }
    return {
      status: 201,
    };
  }
}

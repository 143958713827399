import { useQuery } from '@tanstack/react-query';
import { type SetRequired } from 'type-fest';

import { type PaginatedQuery, type Statement } from '@amalia/core/types';
import { assert } from '@amalia/ext/typescript';
import { type Dataset } from '@amalia/payout-calculation/types';

import { StatementDatasetsApiClient } from '../api-client/statement-datasets.api-client';

export type UseDatasetProps = {
  statementId?: Statement['id'];
  datasetId?: Dataset['id'];
  forecasted: boolean;
  paginationOptions: PaginatedQuery;
};

export const statementDatasetsQueryKeys = {
  all: () => ['statement-datasets'] as const,
  details: ({
    statementId,
    datasetId,
    forecasted,
    paginationOptions,
  }: SetRequired<UseDatasetProps, 'datasetId' | 'statementId'>) =>
    [
      ...statementDatasetsQueryKeys.all(),
      statementId,
      datasetId,
      forecasted,
      paginationOptions.page,
      paginationOptions.search,
      paginationOptions.limit,
      paginationOptions.sort,
      paginationOptions.desc,
    ] as const,
} as const;

export const useStatementDataset = ({ statementId, datasetId, forecasted, paginationOptions }: UseDatasetProps) =>
  useQuery({
    queryKey: statementDatasetsQueryKeys.details({
      statementId: statementId!,
      datasetId: datasetId!,
      forecasted,
      paginationOptions,
    }),
    queryFn: () => {
      assert(statementId, 'Statement ID is required');
      assert(datasetId, 'Dataset ID is required');
      return StatementDatasetsApiClient.fetchPaginatedDatasetRows(
        statementId,
        datasetId,
        {
          ...paginationOptions,
          // Datagrid counts pages starting at 0, but API starts at 1.
          page: (paginationOptions.page ?? 0) + 1,
          search: paginationOptions.search !== '' ? paginationOptions.search : undefined,
        },
        forecasted,
      );
    },
    enabled: !!statementId && !!datasetId,
  });
